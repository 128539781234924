<template>
  <div class="block-payments position-relative mt15" v-click-outside="clearActiveBorder" :class="{'border-none': paymentsList.length === 0}" :key="keyRenderPage">
    <element-select-list
        :indexChangingPayment="getIndexChangingPayment"
    ></element-select-list>
    <div class="total-calc fz12 fw400 text-white" :style="styleObjectCalc" v-if="typesSelection.length">
      Total {{getAmountCalcSelection | formatNumberTwo}}
    </div>
    <counterparties-drop
        v-if="nameColumns.length !== 0 && !hideDropMenu && $route.path.indexOf('cashflow/diagram') === -1"
        class="drop-colums"
        :is-multiple="true"
        :list="nameColumns"
        :title="''"
        :active-item="activeColumnsDefault"
        @close="setActiveColumns"
    ></counterparties-drop>
    <div class="no-payments" v-if="paymentsList.length === 0">
      <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.5905 121.467C34.3947 121.36 34.1483 121.218 33.9803 121.068C32.3841 119.647 30.2728 119.641 28.6707 121.068C27.6139 122.009 26.2481 122.009 25.1914 121.068C24.4072 120.37 23.4893 120.001 22.5366 120.001C21.5839 120.001 20.6659 120.37 19.8818 121.068C18.8249 122.009 17.4592 122.009 16.4024 121.068C14.8062 119.647 12.695 119.641 11.0929 121.068C10.9403 121.204 10.7216 121.344 10.4243 121.498C10.0574 121.687 9.89796 122.172 10.0682 122.581C10.2385 122.989 10.6739 123.167 11.0409 122.977C11.4518 122.765 11.7591 122.563 12.008 122.341C13.0648 121.4 14.4305 121.4 15.4874 122.341C17.0836 123.762 19.1948 123.768 20.7969 122.341C21.8537 121.4 23.2194 121.4 24.2763 122.341C25.8726 123.762 27.9838 123.768 29.5859 122.341C30.6427 121.4 32.0084 121.4 33.0653 122.341C33.3426 122.588 33.6652 122.777 33.9443 122.93C34.3073 123.129 34.7463 122.962 34.9247 122.558C35.1031 122.154 34.9536 121.666 34.5905 121.467Z" fill="#DEE3EA"/>
        <path d="M42.4095 72.467C42.6053 72.3598 42.8517 72.2176 43.0197 72.068C44.6159 70.6466 46.7272 70.6414 48.3293 72.068C49.3861 73.009 50.7519 73.009 51.8086 72.068C52.5928 71.3698 53.5107 71.0007 54.4634 71.0007C55.4161 71.0007 56.3341 71.3698 57.1182 72.068C58.1751 73.009 59.5408 73.009 60.5976 72.068C62.1938 70.6467 64.305 70.6414 65.9071 72.068C66.0597 72.2038 66.2784 72.3444 66.5757 72.498C66.9426 72.6875 67.102 73.1722 66.9318 73.5806C66.7615 73.989 66.3261 74.1665 65.9591 73.977C65.5482 73.7648 65.2409 73.5628 64.992 73.3411C63.9352 72.4001 62.5695 72.4 61.5126 73.3411C59.9164 74.7625 57.8052 74.7677 56.2031 73.3411C55.1463 72.4001 53.7806 72.4 52.7237 73.3411C51.1274 74.7625 49.0162 74.7677 47.4141 73.3411C46.3573 72.4001 44.9916 72.4 43.9347 73.3411C43.6574 73.588 43.3348 73.7774 43.0557 73.9302C42.6927 74.1289 42.2537 73.9623 42.0753 73.5583C41.8969 73.1542 42.0464 72.6657 42.4095 72.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 31.2C32.6627 31.2 33.2 30.6627 33.2 30C33.2 29.3373 32.6627 28.8 32 28.8C31.3373 28.8 30.8 29.3373 30.8 30C30.8 30.6627 31.3373 31.2 32 31.2ZM32 33C33.6569 33 35 31.6569 35 30C35 28.3431 33.6569 27 32 27C30.3431 27 29 28.3431 29 30C29 31.6569 30.3431 33 32 33Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M299 120.8C298.337 120.8 297.8 121.337 297.8 122C297.8 122.663 298.337 123.2 299 123.2C299.663 123.2 300.2 122.663 300.2 122C300.2 121.337 299.663 120.8 299 120.8ZM299 119C297.343 119 296 120.343 296 122C296 123.657 297.343 125 299 125C300.657 125 302 123.657 302 122C302 120.343 300.657 119 299 119Z" fill="#DEE3EA"/>
        <path d="M284.409 27.467C284.605 27.3598 284.852 27.2176 285.02 27.068C286.616 25.6466 288.727 25.6414 290.329 27.068C291.386 28.009 292.752 28.009 293.809 27.068C294.593 26.3698 295.511 26.0007 296.463 26.0007C297.416 26.0007 298.334 26.3698 299.118 27.068C300.175 28.009 301.541 28.009 302.598 27.068C304.194 25.6467 306.305 25.6414 307.907 27.068C308.06 27.2038 308.278 27.3444 308.576 27.498C308.943 27.6875 309.102 28.1722 308.932 28.5806C308.762 28.989 308.326 29.1665 307.959 28.977C307.548 28.7648 307.241 28.5628 306.992 28.3411C305.935 27.4001 304.569 27.4 303.513 28.3411C301.916 29.7625 299.805 29.7677 298.203 28.3411C297.146 27.4001 295.781 27.4 294.724 28.3411C293.127 29.7625 291.016 29.7677 289.414 28.3411C288.357 27.4001 286.992 27.4 285.935 28.3411C285.657 28.588 285.335 28.7774 285.056 28.9302C284.693 29.1289 284.254 28.9623 284.075 28.5583C283.897 28.1542 284.046 27.6657 284.409 27.467Z" fill="#DEE3EA"/>
        <path d="M247.409 69.467C247.605 69.3598 247.852 69.2176 248.02 69.068C249.616 67.6466 251.727 67.6414 253.329 69.068C254.386 70.009 255.752 70.009 256.809 69.068C257.593 68.3698 258.511 68.0007 259.463 68.0007C260.416 68.0007 261.334 68.3698 262.118 69.068C263.175 70.009 264.541 70.009 265.598 69.068C267.194 67.6467 269.305 67.6414 270.907 69.068C271.06 69.2038 271.278 69.3444 271.576 69.498C271.943 69.6875 272.102 70.1722 271.932 70.5806C271.762 70.989 271.326 71.1665 270.959 70.977C270.548 70.7648 270.241 70.5628 269.992 70.3411C268.935 69.4001 267.569 69.4 266.513 70.3411C264.916 71.7625 262.805 71.7677 261.203 70.3411C260.146 69.4001 258.781 69.4 257.724 70.3411C256.127 71.7625 254.016 71.7677 252.414 70.3411C251.357 69.4001 249.992 69.4 248.935 70.3411C248.657 70.588 248.335 70.7774 248.056 70.9302C247.693 71.1289 247.254 70.9623 247.075 70.5583C246.897 70.1542 247.046 69.6657 247.409 69.467Z" fill="#DEE3EA"/>
        <path d="M106 55.9875V114.438C106 122.169 112.268 128.438 120 128.438H176.725M106 55.9875V34.625C106 26.893 112.268 20.625 120 20.625H128.425M106 55.9875H214.675M214.675 55.9875V34.625C214.675 26.893 208.407 20.625 200.675 20.625H192.25M214.675 55.9875V93.9375M136.188 20.625H156.025M163.788 20.625H184.488" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M136.188 70.6504V106.013" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M160.338 70.6504V106.013" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M195.269 79.7061L125.406 79.7061" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M167.669 96.9561L125.406 96.9561" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M184.487 70.6504V86.6066" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <rect x="128.562" y="13" width="7.4875" height="18.7" rx="3.74375" stroke="#DEE3EA" stroke-width="2"/>
        <rect x="156.162" y="13" width="7.4875" height="18.7" rx="3.74375" stroke="#DEE3EA" stroke-width="2"/>
        <rect x="184.625" y="13" width="7.4875" height="18.7" rx="3.74375" stroke="#DEE3EA" stroke-width="2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M176.725 98.4084V145.649C176.725 148.419 179.999 149.824 181.946 147.888L186.791 143.07C187.893 141.975 189.62 141.878 190.833 142.844L198.981 149.335C200.093 150.222 201.656 150.222 202.769 149.335L210.916 142.844C212.129 141.878 213.856 141.975 214.958 143.07L219.804 147.888C221.75 149.824 225.025 148.419 225.025 145.649V98.4084C225.025 95.9392 223.059 93.9375 220.634 93.9375H181.116C178.69 93.9375 176.725 95.9392 176.725 98.4084Z" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M202 118.55H200C198.896 118.55 198 117.699 198 116.65V115.7C198 114.651 198.896 113.8 200 113.8H202C203.105 113.8 204 114.651 204 115.7C204 116.225 204.448 116.65 205 116.65C205.552 116.65 206 116.225 206 115.7C206 113.601 204.209 111.9 202 111.9V110.95C202 110.425 201.552 110 201 110C200.448 110 200 110.425 200 110.95V111.9C197.791 111.9 196 113.601 196 115.7V116.65C196 118.749 197.791 120.45 200 120.45H202C203.105 120.45 204 121.301 204 122.35V123.3C204 124.349 203.105 125.2 202 125.2H200C198.896 125.2 198 124.349 198 123.3C198 122.775 197.552 122.35 197 122.35C196.448 122.35 196 122.775 196 123.3C196 125.399 197.791 127.1 200 127.1V128.05C200 128.575 200.448 129 201 129C201.552 129 202 128.575 202 128.05V127.1C204.209 127.1 206 125.399 206 123.3V122.35C206 120.251 204.209 118.55 202 118.55Z" fill="#DEE3EA"/>
      </svg>
      <p class="no-payments__title fz18 fw500 text-dark" v-if="$route.path.indexOf('instructions') === -1">The list is empty</p>
      <p class="no-payments__title fz18 fw500 text-dark" v-else>Payment Instructions are empty</p>
<!--      <button class="blue-button-text no-payments__button fz14" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('counterparties/') === -1 && $route.path.indexOf('cashflow/diagram') === -1" v-on:click="addNewPayment">Create a new</button>-->
    </div>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="780"
        :filename="getNameFile"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a3"
        pdf-orientation="landscape"
        pdf-content-width="1585px"
        ref="html2Pdf"
        @hasDownloaded="hasDownloaded"
    >
      <div slot="pdf-content" class="pdf-wrap">
        <div class="block-wrap-pdf-content">
          <virtual-payment-list-title
              :showSortButton="false"
              :showEndBlock="false"
              :showCheckBox="false"
              :is-selected="selectedCheckboxes.length !== 0"
              v-if="paymentsList.length"
              :show-settings="getGridPdf.area"
              class="titles"
              :style="{'display': 'grid', 'grid-template-areas': JSON.stringify(getGridPdf.area), 'grid-template-columns': getGridPdf.columns, 'grid-template-rows': '44px'}"
          ></virtual-payment-list-title>
          <div
              v-for="(item, i) in paymentsList"
              :key="i+'213sd'"
              class="payment-item item-pdf"
              :class="{'bg-white-blue': i%2 !== 0, 'bgc-white': i%2 === 0}"
              :style="{'display': 'grid', 'grid-template-areas': JSON.stringify(getGridPdf.area), 'grid-template-columns': getGridPdf.columns, 'grid-template-rows': '100%'}"
          >
            <div class="due-date" :class="{'border-left': $route.path.indexOf('cashflow/diagram')>= 0 || $route.path.indexOf('cashflow/new-diagram')>= 0}" v-if="getGridPdf.area.indexOf('due-date') >= 0">
              <p class="fz12 text-dark">{{item.execute_date}}</p>
            </div>
            <div class="vessel-state-and-member" v-if="getGridPdf.area.indexOf('vessel-state-and-member') >= 0">
              <p class="fz12 text-dark">{{item.ship ? item.ship.name : ''}}</p>
            </div>
            <div class="account" v-if="getGridPdf.area.indexOf('account') >= 0">
              <p class="fz12 text-dark">{{item.account_name ? item.account_name : ''}}</p>
            </div>
            <div class="additionally" v-if="getGridPdf.area.indexOf('additionally') >= 0">
              <p class="fz12 text-dark">{{item.additionally}}</p>
            </div>
            <div class="currency" v-if="getGridPdf.area.indexOf('currency') >= 0">
              <p class="fz12 text-dark">{{item.currency}}</p>
            </div>
            <div class="expense" v-if="getGridPdf.area.indexOf('expense') >= 0">
              <p class="fz12 text-dark">{{(item.payment_type === 'expense' ? item.payment_amount : '0.00') | formatNumberTwo}}</p>
            </div>
            <div class="income" v-if="getGridPdf.area.indexOf('income') >= 0">
              <p class="fz12 text-dark">{{(item.payment_type !== 'expense' ? item.payment_amount : '0.00') | formatNumberTwo}}</p>
            </div>
            <div class="converted" v-if="getGridPdf.area.indexOf('converted') >= 0">
              <p class="fz12 text-dark">{{item.currency_amount | formatNumberTwo}}</p>
            </div>
            <div class="status" v-if="getGridPdf.area.indexOf('status') >= 0">
              <p class="text-info fz12 text-dark" :class="{'text-red': item.status !== 'paid'}">{{item.status === 'paid' ? 'Paid' : 'Not Paid'}}</p>
            </div>
            <div class="paid-date" v-if="getGridPdf.area.indexOf('paid-date') >= 0">
              <p class="fz12 text-dark">{{item.paid_date | formatDateFromCpDateWithotTimeZome}}</p>
            </div>
            <div class="counterparty" v-if="getGridPdf.area.indexOf('counterparty') >= 0">
              <p class="fz12 text-dark">{{item.client_name}}</p>
            </div>
            <div class="bank-and-cash-account fz12 text-dark"  v-if="getGridPdf.area.indexOf('bank-and-cash-account') >= 0">
              <p class="fz12 text-dark">{{item.bank ? item.bank.account.account_id + ': ' + item.bank.account.name : (item.cash ? item.cash.name : '')}}</p>
            </div>
            <div class="remark" v-if="getGridPdf.area.indexOf('remark') >= 0">
              <p class="fz12 text-dark">{{item.comment}}</p>
            </div>
            <div class="attachment" v-if="getGridPdf.area.indexOf('attachment') >= 0">
              <div class="flex-center click-element-border needed-after">
                <svg v-if="item.status === 'paid' && item.files.length" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H8.06611C6.41648 19.4 5.07635 18.0681 5.06617 16.4185L5 5.7Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H15.2C14.8134 19.4 14.5 19.0866 14.5 18.7C14.5 18.3134 14.8134 18 15.2 18H16.7C17.418 18 18 17.418 18 16.7V10.2125H14.0462C13.1073 10.2125 12.3462 9.45138 12.3462 8.5125V4.4H7.7C6.98203 4.4 6.4 4.98203 6.4 5.7V8.7C6.4 9.0866 6.0866 9.4 5.7 9.4C5.3134 9.4 5 9.0866 5 8.7V5.7ZM13.7462 5.42358L17.0426 8.8125H14.0462C13.8805 8.8125 13.7462 8.67818 13.7462 8.5125V5.42358Z" fill="#2C2C2C"/>
                  <path d="M6.17355 16.8696V13.5868C6.17355 12.7104 5.46313 12 4.58678 12V12C3.71042 12 3 12.7104 3 13.5868V18C3 19.6569 4.34315 21 6 21V21C7.65685 21 9 19.6569 9 18V12" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
                </svg>
                <svg v-else-if="item.files.length" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H8.06611C6.41648 19.4 5.07635 18.0681 5.06617 16.4185L5 5.7Z" fill="#E9ED22"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H15.2C14.8134 19.4 14.5 19.0866 14.5 18.7C14.5 18.3134 14.8134 18 15.2 18H16.7C17.418 18 18 17.418 18 16.7V10.2125H14.0462C13.1073 10.2125 12.3462 9.45138 12.3462 8.5125V4.4H7.7C6.98203 4.4 6.4 4.98203 6.4 5.7V8.7C6.4 9.0866 6.0866 9.4 5.7 9.4C5.3134 9.4 5 9.0866 5 8.7V5.7ZM13.7462 5.42358L17.0426 8.8125H14.0462C13.8805 8.8125 13.7462 8.67818 13.7462 8.5125V5.42358Z" fill="#2C2C2C"/>
                  <path d="M6.17355 16.8696V13.5868C6.17355 12.7104 5.46313 12 4.58678 12V12C3.71042 12 3 12.7104 3 13.5868V18C3 19.6569 4.34315 21 6 21V21C7.65685 21 9 19.6569 9 18V12" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
            <div class="bank-receipt" v-if="getGridPdf.area.indexOf('bank-receipt') >= 0">
              <div class="flex-center click-element-border needed-after">
                <svg v-if="item.receipt_files.length !== 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.99609 5.7C4.99609 4.20883 6.20492 3 7.69609 3L13.342 3L19.4002 9.22821V16.6896C19.4002 18.1808 18.1914 19.3896 16.7002 19.3896H8.06219C6.41257 19.3896 5.07244 18.0577 5.06225 16.4081L4.99609 5.7Z" fill="#37D379"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H15.2C14.8134 19.4 14.5 19.0866 14.5 18.7C14.5 18.3134 14.8134 18 15.2 18H16.7C17.418 18 18 17.418 18 16.7V10.2125H14.0462C13.1073 10.2125 12.3462 9.45138 12.3462 8.5125V4.4H7.7C6.98203 4.4 6.4 4.98203 6.4 5.7V8.7C6.4 9.0866 6.0866 9.4 5.7 9.4C5.3134 9.4 5 9.0866 5 8.7V5.7ZM13.7462 5.42358L17.0426 8.8125H14.0462C13.8805 8.8125 13.7462 8.67818 13.7462 8.5125V5.42358Z" fill="#2C2C2C"/>
                  <path d="M5.67355 16.8696V13.5868C5.67355 12.7104 4.96313 12 4.08678 12V12C3.21042 12 2.5 12.7104 2.5 13.5868V18C2.5 19.6569 3.84315 21 5.5 21V21C7.15685 21 8.5 19.6569 8.5 18V12" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
            <div class="comment-from-accounting" v-if="getGridPdf.area.indexOf('comment-from-accounting') >= 0">
              <div class="flex-center click-element-border needed-after">
                <svg v-if="item.has_messages" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0835 6.61999C20.0835 5.41084 19.076 4.40332 17.8668 4.40332H6.1335C4.92435 4.40332 3.91683 5.41084 3.91683 6.61999V14.6867C3.91683 15.8958 4.92435 16.9033 6.1335 16.9033H6.60572V18.5978C6.60572 19.0081 6.8579 19.3769 7.24122 19.5253L7.24467 19.5266L7.24468 19.5266C7.3588 19.5701 7.47918 19.5922 7.60016 19.5922C7.83687 19.5922 8.06877 19.5074 8.25101 19.3495L11.8501 16.9033H17.8668C19.076 16.9033 20.0835 15.8958 20.0835 14.6867V6.61999ZM17.8668 5.90332C18.2475 5.90332 18.5835 6.23927 18.5835 6.61999V14.6867C18.5835 15.0674 18.2475 15.4033 17.8668 15.4033H11.6193C11.469 15.4033 11.3221 15.4485 11.1977 15.533L8.10572 17.6346V16.1533C8.10572 15.7391 7.76993 15.4033 7.35572 15.4033H6.1335C5.75278 15.4033 5.41683 15.0674 5.41683 14.6867V6.61999C5.41683 6.23927 5.75278 5.90332 6.1335 5.90332H17.8668Z" fill="#4B4B4B"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 9C7.75 8.58579 8.08579 8.25 8.5 8.25H15.5C15.9142 8.25 16.25 8.58579 16.25 9C16.25 9.41421 15.9142 9.75 15.5 9.75H8.5C8.08579 9.75 7.75 9.41421 7.75 9Z" fill="#4B4B4B"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 12C7.75 11.5858 8.08579 11.25 8.5 11.25H12C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75H8.5C8.08579 12.75 7.75 12.4142 7.75 12Z" fill="#4B4B4B"/>
                </svg>
              </div>
            </div>
            <div class="settled-vsl" v-if="getGridPdf.area.indexOf('settled-vsl') >= 0">
              <p class="fz12 text-dark">{{item.settled_ship ? item.settled_ship.name : ''}}</p>
            </div>
            <div class="disbursement" v-if="getGridPdf.area.indexOf('disbursement') >= 0">
              <p class="fz12 text-dark">{{item.ports ? item.ports.name : ''}}</p>
            </div>
            <div class="real-account" v-if="getGridPdf.area.indexOf('real-account') >= 0">
              <p class="fz12 text-dark">{{item.real_account ? item.real_account.company_name : ''}}</p>
            </div>
            <div class="statement-member" v-if="getGridPdf.area.indexOf('statement-member') >= 0">
              <p class="fz12 text-dark">{{item.statement_member}}</p>
            </div>
            <div class="balance" v-if="getGridPdf.area.indexOf('balance') >= 0">
              <p class="fz12 text-dark">{{item.balance | formatNumberTwo}}</p>
            </div>
          </div>
        </div>

      </div>
    </vue-html2pdf>
    <virtual-payment-list-title
        @select-all="selectCheckboxAll"
        :is-selected="selectedCheckboxes.length !== 0"
        @changeOrderingStatus="(e)=> {$emit('changeOrderingStatus', e)}"
        @changeOrderingDueDate="(e)=> {$emit('changeOrderingDueDate', e)}"
        @changeOrdering="(e)=> {$emit('changeOrdering', e)}"
        v-if="paymentsList.length"
        :show-settings="gridSettings.area"
        class="titles"
      :style="{'display': 'grid', 'grid-template-areas': JSON.stringify(gridSettings.area), 'grid-template-columns': gridSettings.columns, 'grid-template-rows': '44px'}"
    ></virtual-payment-list-title>
      <RecycleScroller
          ref="list"
          page-mode
          class="scroller position-relative"
          :items="paymentsList"
          :item-size="31"
          key-field="id"
          v-slot="{ item }"
      >
<!--        <p class="asdas">{{getIdPayment(item.id)}} {{item.id}}</p>-->
        <virtual-payment-list-item
            :showLoader="showLoaderIds.indexOf(item.id)>=0"
            @delete="unarchivedThis(item.id)"
            @selected="selectCheckbox(item.id)"
            :is-selected="selectedCheckboxes.indexOf(item.id) === -1"
            @mousedown="(e) => (mousedown({index: getIdPayment(item.id), type: e, id: item.id, element: 'payment'+item.id}))"
            @mouseup="(e) => (mouseup({index: getIdPayment(item.id), type: e, id: item.id}))"
            @on-change="onChange(item.id)"
            :key="item.id + 's' +keyRender"
            class="payment-item"
            :id="'payment'+item.id"
            :class="{'bg-white-blue': getIdPayment(item.id)%2 !== 0, 'bgc-white': getIdPayment(item.id)%2 === 0}"
            :item="item"
            :style="{'display': 'grid', 'grid-template-areas': JSON.stringify(gridSettings.area), 'grid-template-columns': gridSettings.columns, 'grid-template-rows': '31px'}"
            :show-settings="gridSettings.area"
            :disabled-items="disabledPayments[getIdPayment(item.id)]"
        ></virtual-payment-list-item>
      </RecycleScroller>
    <div class="bottom-panel" v-if="($route.path.indexOf('archive') >= 0 || $route.path.indexOf('open/accounting-invoice') >=0) && selectedCheckboxes.length">
      <p class="fz13 fw500 text text-gray">Selected <span>{{selectedCheckboxes.length}}/{{paymentsList.length}}</span></p>
      <button class="unarchive fz14 fw500" v-on:click="unArchivedSelected" v-if="getPermissionsForUnArchive() === true">
        <svg v-if="$route.path.indexOf('archive') >= 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
          <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#9AA3B0"/>
        </svg>
        <svg v-if="$route.path.indexOf('archive') === -1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#9AA3B0"/>
        </svg>

        {{$route.path.indexOf('archive') >= 0 ? 'Unarchive' : 'Archive'}}
      </button>
      <button class="cancel" v-on:click="clearAllSelected">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
          <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import VirtualPaymentListItem from "@/components/payment/payment-list/virtual-payment-list-item";
import VirtualPaymentListTitle from "@/components/payment/payment-list/virtual-payment-list-title";
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ElementSelectList from "@/components/payment/payment-list/elements-item/element-select-list";
import CounterpartiesDrop from "@/components/counterparties/counterpartiesAdd/counterpartiesDrop";
import moment from 'moment';
export default {
  name: "virtual-payment-list",
  components: {CounterpartiesDrop, ElementSelectList, VirtualPaymentListTitle, VirtualPaymentListItem},
  props: {
    shortView: Boolean,
  },
  computed: {
    ...mapState({
      pushDataPaymentList: state => state.payment.pushDataPaymentList,
      selectedItem: state => state.paymentListXY.selectedItem,
      listSettings: state => state.paymentListXY.listSettings,
      isShow: state => state.paymentListXY.isShow,
      settingsForPath: state => state.paymentListXY.settingsForPath,
      pausedChange: state => state.paymentListXY.pausedChange,
      typesSelection: state => state.paymentListXY.typesSelection,
      typesSelectionExpense: state => state.paymentListXY.typesSelectionExpense,
      typesSelectionIncome: state => state.paymentListXY.typesSelectionIncome,
      typesSelectionCurrency: state => state.paymentListXY.typesSelectionCurrency,
      emitChangeDataSelected: state => state.paymentListXY.emitChangeDataSelected,
      dataNewPayment: state => state.payment.dataNewPayment,

    }),
    ...mapGetters(['getDartMode', 'getPermissionsByType', 'getPermissionsFullByType', 'getFilterDataPaymentAxios', 'getAmountBalance']),
    getNameFile() {
      if(this.$route.path.indexOf('cashflow/diagram') >= 0) {
        return 'Cash Flow Projection'
      }
      if(this.$route.path.indexOf('cashflow/new-diagram') >= 0) {
        return 'Cash Flow Diagram'
      }
      if(this.$route.path.indexOf('cashflow/list') >= 0) {
        return 'Cash Flow List'
      }
      if(this.$route.path.indexOf('payment/instructions') >= 0) {
        return 'Payment Instructions'
      }
      if(this.$route.path.indexOf('open/cash-flow') >= 0) {
        return 'Vessels Cash Flow'
      }
      if(this.$route.path.indexOf('open/accounting-invoice') >= 0) {
        return 'Vessels Acc. Invoices'
      }
        return 'Payment List'
    },
    getGridPdf() {
      if(this.$route.path.indexOf('cashflow/diagram') >= 0 || this.$route.path.indexOf('cashflow/new-diagram') >= 0) {
        let names = '';
        let columns = '';
        this.elementsGridSettings.orderElements.forEach(item=> {
          let element = this.elementsGridSettings.elements.filter(a=> a.name !== 'block-for-checkbox' && a.name !== 'end-block').find(a=> a.name === item)
          if(element && element.show) {
            names+= ' '+element.name
            columns+= ' '+element.width
          }
        })
        return {area: names, columns: columns}
      }
      let elements = this.elementsGridSettings.elements.filter(a=> a.name !== 'block-for-checkbox' && a.name !== 'end-block' && a.name !== 'balance' && (this.nameColumns.map(a=> {return a.value}).indexOf(a.name) >= 0 || this.gridSettings.area.indexOf(a.name) >= 0))
      let names = '';
      let columns = '';
      elements.forEach(element=> {
          names+= ' '+element.name
          columns+= ' '+element.width
      })
      return {area: names, columns: columns}
    },
    gridSettings() {
      let names = '';
      let columns = '';
      this.elementsGridSettings.orderElements.forEach(item=> {
        let element = this.elementsGridSettings.elements.find(a=> a.name === item)
        if(element && element.show) {
          names+= ' '+element.name
          columns+= ' '+element.width
        }
      })
    this.$emit('changeGridSettings', {area: names, columns: columns})
      return {area: names, columns: columns}
    },
    getIndexChangingPayment() {
      return this.paymentsList.findIndex(item=> item.id === this.listSettings.id)
    },
    getAmountCalcSelection() {
      let amount = 0;
      if(this.typesSelectionCurrency.length) {
        this.typesSelectionCurrency.forEach(item=> {
          let payment = this.paymentsList[item.index]
          if(!payment) {
            return 0;
          }
          if(payment.payment_type == 'expense') {
            amount = amount - parseFloat(payment.currency_amount);
          } else {
            amount = amount + parseFloat(payment.currency_amount);
          }
        })
      } else {
        this.typesSelectionExpense.forEach(item=> {
          let payment = this.paymentsList[item.index]
          if(!payment) {
            return 0;
          }
          amount = payment.payment_type == 'expense' ? (amount - parseFloat(payment.payment_amount)) : (amount)
        })
        this.typesSelectionIncome.forEach(item=> {
          let payment = this.paymentsList[item.index]
          if(!payment) {
            return 0;
          }
          amount = payment.payment_type == 'income' ? (amount + parseFloat(payment.payment_amount)) : (amount)
        })
      }
      return amount
    },
  },
  data() {
    return {
      hideDropMenu: false,
      showLoaderIds: [],
      selectedCheckboxes: [],
      styleObjectCalc: {
        left: 0,
        top: 0,
      },
      mouseDown: false,
      paymentsList: [],
      keyRender: -34234234,
      keyRenderPage: -237486234,
      permissionsForPayments: [],
      elementsGridSettings: {
        elements: [
          {
            name: 'block-for-checkbox',
            show: this.$route.path.indexOf('archive') >= 0 || this.$route.path.indexOf('open/accounting-invoice') >=0,
            width: '36px',
          },
          {
            name: 'due-date',
            show: true,
            width: '80px',
          },
          {
            name: 'vessel-state-and-member',
            show: true,
            width: '6.69fr',
          },
          {
            name: 'account',
            show: true,
            width: '10.3fr',
          },
          {
            name: 'additionally',
            show: true,
            width: '8.79fr',
          },
          {
            name: 'currency',
            show: true,
            width: '40px',
          },
          {
            name: 'expense',
            show: true,
            width: '9.7fr',
          },
          {
            name: 'income',
            show: true,
            width: '9.7fr',
          },
          {
            name: 'converted',
            show: true,
            width: '8.19fr',
          },
          {
            name: 'status',
            show: true,
            width: '67px',
          },
          {
            name: 'paid-date',
            show: true,
            width: '80px',
          },
          {
            name: 'counterparty',
            show: true,
            width: '7.89fr',
          },
          {
            name: 'bank-and-cash-account',
            show: true,
            width: '9.4fr',
          },
          {
            name: 'remark',
            show: true,
            width: '7.67fr',
          },
          {
            name: 'attachment',
            show: true,
            width: '54px',
          },
          {
            name: 'bank-receipt',
            show: true,
            width: '50px',
          },
          {
            name: 'comment-from-accounting',
            show: true,
            width: '55px',
          },
          {
            name: 'settled-vsl',
            show: true,
            width: '7.44fr',
          },
          {
            name: 'disbursement',
            show: true,
            width: '7.44fr',
          },
          {
            name: 'real-account',
            show: true,
            width: '6.69fr',
          },
          {
            name: 'statement-member',
            show: true,
            width: '7.44fr'
          },
          {
            name: 'balance',
            show: false,
            width: '105px'
          },
          {
            name: 'end-block',
            show: true,
            width: '35px'
          }

        ],
        orderElements: ['block-for-checkbox', 'due-date', 'vessel-state-and-member', 'account', 'additionally', 'currency', 'expense', 'income', 'converted',
          'status', 'paid-date', 'counterparty', 'bank-and-cash-account', 'remark', 'attachment', 'bank-receipt', 'comment-from-accounting', 'settled-vsl', 'disbursement', 'real-account', 'statement-member', 'balance', 'end-block'],
      },
      namePages: [],
      nameColumns: [],
      activeColumnsDefault: [],
      disabledPayments: [],
      emptyPermissionDisabledObject: {
        due_date: true,
        vessel_state_and_member: true,
        account: true,
        additionally: true,
        currency: true,
        expense: true,
        income: true,
        converted: true,
        counterparty: true,
        bank_and_cash_account: true,
        remark: true,
        settled_vsl: true,
        disbursement: true,
        real_account: true,
        statement_member: true,
      },
      keyCtrlDown: false,
      elementActiveBorder: null,
    }
  },
  watch: {
    dataNewPayment: function () {
        let data = JSON.parse(JSON.stringify(this.dataNewPayment))
        let obj = JSON.parse(JSON.stringify(this.emptyPermissionDisabledObject))
        obj.due_date = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
        obj.vessel_state_and_member = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
        obj.account = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
        obj.additionally = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
        obj.currency = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
        obj.expense = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
        obj.income = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
        obj.converted = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
        obj.counterparty = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
        obj.bank_and_cash_account = (this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)) || this.getPermissionBlockChangeBank(data.status)
        obj.remark = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
        obj.settled_vsl = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
        obj.disbursement = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
        obj.real_account = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
        obj.statement_member = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
        this.disabledPayments.push(obj)
      console.log(data, this.paymentsList[this.paymentsList.length-1])

      // this.paymentsList.push(data)
      this.paymentsList = this.paymentsList.concat((JSON.parse(JSON.stringify([data]))))
      // if(this.$route.path.indexOf('open/cash-flow')>=0) {
      //   this.keyRenderPage++
      // }
      this.checkBalance()
      // this.paymentsList = JSON.parse(JSON.stringify(this.paymentsList))

    },
    shortView: function () {
      if(this.shortView) {
        this.nameColumns.forEach(a=> {
          let el = this.elementsGridSettings.elements.find(b=> b.name === a.value)
          if(this.shortView) {
            el.show = false
          }
        })
      } else {
       this.setActiveColumns(this.activeColumnsDefault)
      }
    },
    pushDataPaymentList: function () {
     this.pushDataPaymentList.forEach(el=> {
       let data = JSON.parse(JSON.stringify(el))
       let obj = JSON.parse(JSON.stringify(this.emptyPermissionDisabledObject))
       obj.due_date = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
       obj.vessel_state_and_member = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
       obj.account = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
       obj.additionally = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
       obj.currency = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
       // getBoolDontSelect({bank: paymentsList[i].bank, cash: paymentsList[i].cash})
       obj.expense = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
       obj.income = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
       obj.converted = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
       obj.counterparty = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status) || this.getPermissionBlockBreakDown()
       obj.bank_and_cash_account = (this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)) || this.getPermissionBlockChangeBank(data.status)
       obj.remark = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
       obj.settled_vsl = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
       obj.disbursement = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
       obj.real_account = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
       obj.statement_member = this.getPermissionEditPaymentInStatusPaid(data.status) || this.getPermissionsBlockChangeListInStatusPaid(data.status)
       this.disabledPayments.push(obj)
      })
      this.paymentsList = this.paymentsList.concat((JSON.parse(JSON.stringify(this.pushDataPaymentList))))
      this.paymentsList = this.paymentsList.reduce((acc, n) => {
        const i = acc.findIndex(m => m.id === n.id);
        if (!~i || !acc[i].checked) {
          acc.push(n);
          if (~i) {
            acc.splice(i, 1);
          }
        }
        return acc;
      }, [])
      // let values = this.paymentsList.map(a=> {return a.id})
// предположим, что у нас есть числовой массив
//       let duplicates = values.filter((number, index, numbers) => {
//         console.log(number); // number - элемент массива
//         console.log(index); // index - индекс элемента массива
//         console.log(numbers); // numbers - представление массива values
//         return numbers.indexOf(number) !== index;
//       });
//       console.log(duplicates)
      this.checkBalance()
      // let array = this.paymentsList
      // this.paymentsList = []
      // this.paymentsList = JSON.parse(JSON.stringify(array))
      setTimeout(()=> {
        this.checkAutoScroll();
      }, 1000)


    },
    emitChangeDataSelected: function () {
      if(this.settingsForPath.id) {
        console.log(1)
        let index = this.paymentsList.findIndex(a=> a.id === this.settingsForPath.id)
        let boolChangeOnlyText = false
        let item = JSON.parse(JSON.stringify(this.paymentsList[index]))
        if(this.settingsForPath.nameValueText && this.settingsForPath.nameValueText.length) {
          if((item[this.settingsForPath.nameValue] === this.selectedItem) || (item[this.settingsForPath.nameValue] && this.selectedItem && item[this.settingsForPath.nameValue].id === this.selectedItem.id)) {
            item[this.settingsForPath.nameValue] = null
            item[this.settingsForPath.nameValueText] = this.settingsForPath.searchText
            boolChangeOnlyText = true
          } else {
            item[this.settingsForPath.nameValue] = this.selectedItem
            item[this.settingsForPath.nameValueText] = this.selectedItem ? (this.settingsForPath.nameValueText === 'account_name' ? (this.settingsForPath.watchKeyBefore ? this.selectedItem[this.settingsForPath.watchKeyBefore] + ': ' + this.selectedItem[this.settingsForPath.watchKey] : this.selectedItem[this.settingsForPath.watchKey]) : this.selectedItem[this.settingsForPath.watchKey]) : ''
          }
        } else {
          if(this.settingsForPath.nameValue === 'bank') {
            if(this.selectedItem) {
              item[(this.selectedItem.type && this.selectedItem.type === 'cash') ? 'cash' : 'bank'] = this.selectedItem
              item[(this.selectedItem.type && this.selectedItem.type === 'cash') ? 'bank' : 'cash'] = null
            } else {
              item.bank = null
              item.cash = null
            }
          } else {
            item[this.settingsForPath.nameValue] = this.selectedItem
          }
        }
        if(this.settingsForPath.nameValue === 'ship' || (this.settingsForPath.nameValue === 'client' && !boolChangeOnlyText) || this.settingsForPath.nameValue === 'real_account') {
          item.statement_member = ''
          if(this.settingsForPath.nameValue === 'client' && item.ship && item.client) {
            console.log('client', item.ship, item.client)
            let cargo_contracts = item.ship.cargo_contracts.filter(a=> a.owner == item.client.id)
            console.log('client cargo_contracts', cargo_contracts)
            if(cargo_contracts.length && cargo_contracts.length == 1) {
              item.statement_member = cargo_contracts[0].cp_date
            }
          }
        }
        this.$set(this.paymentsList, index, item)
        let time = 0
        if(this.settingsForPath.nameValue === 'currency') {
          time = 100
        }
        console.log(2)
        console.log(this.settingsForPath.nameValue, this.settingsForPath.nameValue === 'currency' || this.settingsForPath.nameValue === 'currency_amount' || this.settingsForPath.nameValue === 'payment_amount')
        setTimeout( ()=> {
          if(this.settingsForPath.nameValue === 'currency' || this.settingsForPath.nameValue === 'currency_amount' || this.settingsForPath.nameValue === 'payment_amount') {
            this.checkBalance()
          }
          this.onChange(item.id)
        }, time)

      }
    },
    typesSelection: function () {
      if(this.typesSelection.length) {
        let left = 0;
        let top = 45;
        // console.log(this.$refs.list)
        let index = this.typesSelectionCurrency.length ? this.typesSelectionCurrency[this.typesSelectionCurrency.length-1].index : Math.max((this.typesSelectionIncome.length ? this.typesSelectionIncome[this.typesSelectionIncome.length -1].index : 0), (this.typesSelectionExpense.length ? this.typesSelectionExpense[this.typesSelectionExpense.length -1].index : 0))
        // index+=1
        // console.log(index)
        // console.log(this.$refs.list.$children[index].$el.offsetParent.style.transform)
        // top = (Number((''+this.$refs.list.$children[index].$el.offsetParent.style.transform).replace(/[^0-9]/g, "")) + 45 + 31) + 'px';
        // (index && this.$refs.list.$children[index] ? this.$refs.list.$children[index].$el.offsetParent.style.transform : 0) + 'px'
        let className = this.typesSelectionCurrency.length ? '.currency.input-payment' : (((this.typesSelectionIncome.length && this.typesSelectionExpense.length && this.typesSelectionIncome[this.typesSelectionIncome.length-1].index >= this.typesSelectionExpense[this.typesSelectionExpense.length-1].index) || this.typesSelectionIncome.length && !this.typesSelectionExpense.length) ? '.income' : '.expense');
        // console.dir(this.$refs.list.$children[index].$el.querySelector(className))
        // console.log(this.$refs.list.$children[index].$el.querySelector(className))
        for(let i=0; i<=index; i++) {
          top+=31
        }
        // console.log(this.$refs.list, index)
        let elementList = document.getElementById('payment'+this.paymentsList[index].id)
        // console.log(elementList)
        // console.log('this.mouseDown.element', this.mouseDown.element.querySelector(className), this.mouseDown.element.querySelector(className).offsetLeft)
        // let element = this.$refs.list.$children[index].$el.querySelector(className)
        let element = elementList ? elementList.querySelector(className) : null

        left = element ? (Number(element.offsetLeft)+1) + Number(element.clientWidth) + 'px' : 0
        if(!element) {
          switch (className) {
            case '.expense':
              left = this.mouseDown.leftExpense
              break;
            case '.income':
              left = this.mouseDown.leftIncome
              break;
            case '.currency.input-payment':
              left = this.mouseDown.leftCurrency
              break;
          }
        }
        this.styleObjectCalc.left = left;
        this.styleObjectCalc.top = top + 'px';
      } else {
        this.styleObjectCalc.left = 0
        this.styleObjectCalc.top = 0
      }
      // console.log(this.typesSelection)
      // console.log('log', this.styleObjectCalc)

    },
    $route: function () {
      this.setSelectionPayments([])
      // this.keyRenderPage++;
      // this.keyRender++;
      // this.mountedOperation();
    },
  },
  methods: {
    ...mapActions(['axiosChangeNote', 'axiosGetGrossNet', 'axiosGetTotalCashFlow', 'axiosArchivePayment', 'axiosGetXLSX']),
    ...mapMutations(['setSelectionPayments', 'addTip', 'setTurnOnDarkModeAfterDownloadPDF', 'setDartMode', 'showLoad', 'hideLoad']),
    downloadPDF() {
      let DOM = this.$refs.html2Pdf.$el.children[0].children[0].children[0].children[0].children;
      let top = 0;
      let offsetTopMain = 0
      console.log(top, offsetTopMain)
      console.log(DOM)
      DOM.forEach((item, i)=> {
              if (((offsetTopMain + item.scrollHeight) - top) > 1087 && i > 0) {
                console.log(((offsetTopMain + item.scrollHeight) - top))
                console.dir(DOM[i - 1]);
                DOM[i - 1].classList.add('html2pdf__page-break')
                DOM[i].classList.add('break-after')

                // DOM[i-1]
                console.log(DOM[i - 1]);
                console.log(DOM[i])
                top = offsetTopMain;
              }
      offsetTopMain+=item.scrollHeight

    })
      this.showLoad()

      this.setTurnOnDarkModeAfterDownloadPDF(this.getDartMode)
      this.setDartMode(false)
      setTimeout(()=>{this.$refs.html2Pdf.generatePdf()}, 1000)
    },
    hasDownloaded() {
      this.hideLoad();
    },
    downloadXML() {
      let formAxios= {
        sheet_title: "Payment List",
        file_name: "Payment List",
        data: []
      }
        formAxios.data.push({id: formAxios.data.length + 1, values: []});
      this.getGridPdf.area.split(' ').forEach(name=> {
        if(name === 'due-date') {
          formAxios.data[0].values.push('Due Date')
        }
        if(name === 'vessel-state-and-member') {
          formAxios.data[0].values.push('Vessel, CP Date')
        }
        if(name === 'account') {
          formAxios.data[0].values.push('Reference')
        }
        if(name === 'additionally') {
          formAxios.data[0].values.push('Additionally')
        }
        if(name === 'currency') {
          formAxios.data[0].values.push('Currency')
        }
        if(name === 'expense') {
          formAxios.data[0].values.push(this.$route.path.indexOf('accounting-invoice')>=0 ? 'Bill' : 'Payments')
        }
        if(name === 'income') {
          formAxios.data[0].values.push(this.$route.path.indexOf('accounting-invoice')>=0 ? 'Invoice' : 'Incomes')
        }
        if(name === 'converted') {
          formAxios.data[0].values.push('USD amount')
        }
        if(name === 'status') {
          formAxios.data[0].values.push('Status')
        }
        if(name === 'counterparty') {
          formAxios.data[0].values.push('Counterparty')
        }
        if(name === 'bank-and-cash-account') {
          formAxios.data[0].values.push('Bank and Cash Accounts')
        }
        if(name === 'remark') {
          formAxios.data[0].values.push('Remarks')
        }
        if(name === 'attachment') {
          formAxios.data[0].values.push('Attachments')
        }
        if(name === 'bank-receipt') {
          formAxios.data[0].values.push('Bank Receipt')
        }
        if(name === 'comment-from-accounting') {
          formAxios.data[0].values.push('Account. remarks')
        }
        if(name === 'settled-vsl') {
          formAxios.data[0].values.push('Settled vsl')
        }
        if(name === 'disbursement') {
          formAxios.data[0].values.push('Disbursements')
        }
        if(name === 'real-account') {
          formAxios.data[0].values.push('Real Account')
        }
        if(name === 'statement-member') {
          formAxios.data[0].values.push('Statem. Member')
        }
        if(name === 'balance') {
          formAxios.data[0].values.push('Balance, USD')
        }
      })

      this.paymentsList.forEach(item=> {
        formAxios.data.push({id: formAxios.data.length + 1, values: []});
        this.getGridPdf.area.split(' ').forEach(name=> {
          if(name === 'due-date') {
            formAxios.data[formAxios.data.length-1].values.push(item.execute_date ? item.execute_date : null)
          }
          if(name === 'vessel-state-and-member') {
            formAxios.data[formAxios.data.length-1].values.push(item.ship ? item.ship.name : null)
          }
          if(name === 'account') {
            formAxios.data[formAxios.data.length-1].values.push(item.account_name ? item.account_name : null)
          }
          if(name === 'additionally') {
            formAxios.data[formAxios.data.length-1].values.push(item.additionally ? item.additionally : null)
          }
          if(name === 'currency') {
            formAxios.data[formAxios.data.length-1].values.push(item.currency ? item.currency : null)
          }
          if(name === 'expense') {
            formAxios.data[formAxios.data.length-1].values.push(this.getFormatNumber(item.payment_type === 'expense' ? item.payment_amount : 0))
          }
          if(name === 'income') {
            formAxios.data[formAxios.data.length-1].values.push(this.getFormatNumber(item.payment_type === 'income' ? item.payment_amount : 0))
          }
          if(name === 'converted') {
            formAxios.data[formAxios.data.length-1].values.push(this.getFormatNumber(item.currency_amount ? item.currency_amount : 0))
          }
          if(name === 'status') {
            formAxios.data[formAxios.data.length-1].values.push(item.status === 'paid' ? 'Paid' : 'Not Paid')
          }
          if(name === 'counterparty') {
            formAxios.data[formAxios.data.length-1].values.push(item.client_name ? item.client_name : null)
          }
          if(name === 'bank-and-cash-account') {
            formAxios.data[formAxios.data.length-1].values.push((item.bank ? item.bank.account.account_id + ': ' + item.bank.account.name : (item.cash ? item.cash.name : null)))
          }
          if(name === 'remark') {
            formAxios.data[formAxios.data.length-1].values.push(item.comment ? item.comment : null)
          }
          if(name === 'attachment') {
            formAxios.data[formAxios.data.length-1].values.push(item.files.length ? '+' : '-')
          }
          if(name === 'bank-receipt') {
            formAxios.data[formAxios.data.length-1].values.push(item.receipt_files.length ? '+' : '-')
          }
          if(name === 'comment-from-accounting') {
            formAxios.data[formAxios.data.length-1].values.push(item.has_messages ? '+' : '-')
          }
          if(name === 'settled-vsl') {
            formAxios.data[formAxios.data.length-1].values.push(item.settled_ship ? item.settled_ship.name : null)
          }
          if(name === 'disbursement') {
            formAxios.data[formAxios.data.length-1].values.push(item.ports ? item.ports.name : null)
          }
          if(name === 'real-account') {
            formAxios.data[formAxios.data.length-1].values.push(item.real_account ? item.real_account.company_name : null)
          }
          if(name === 'statement-member') {
            formAxios.data[formAxios.data.length-1].values.push(item.statement_member ? item.statement_member : null)
          }
          if(name === 'balance') {
            formAxios.data[formAxios.data.length-1].values.push(this.getFormatNumber(item.balance))
          }
        })

      })
      this.axiosGetXLSX(formAxios).then(response => {
        let link = response.data;
        function download(content, contentType, fileName) {
          var link1 = document.createElement("a");
          link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
          link1.download = fileName;
          link1.click();
        }
        download(link, "application/vnd.ms-excel", this.getNameFile+".xls")
      });
      console.log(formAxios)
    },
    getFormatNumber(value) {
      let fixedNumber = parseFloat(value)
      fixedNumber = fixedNumber ? fixedNumber : 0
      fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      return fixedNumber
    },
    clearAllSelected() {
      this.selectCheckboxAll();
    },
    getPermissionsForUnArchive() {
      return this.getPermissionsByType("unarchive_payments_incomes");
    },
    unarchivedThis(id) {
      let text = this.paymentsList.filter(a=> a.id === id);
      if(text.length !== 0) {
        text = text[0].client_name ? text[0].client_name : (text[0].payment_type === 'expense' ? 'Payment' : 'Income');
      }
      this.axiosArchivePayment({id: id, is_archived: false}).then(()=> {
      })
      this.addTip(text + " is unarchived");
      this.paymentsList = this.paymentsList.filter(a=> a.id !== id)
    },

    unArchivedSelected() {
      for (let i=0; i<this.selectedCheckboxes.length; i++) {
        this.axiosArchivePayment( {id: this.selectedCheckboxes[i], is_archived: !(this.$route.path.indexOf('archive') >= 0)}).then(()=> {
        });
      }
      this.addTip(this.selectedCheckboxes.length + (this.$route.path.indexOf('archive') >= 0 ? ' is unarchived' : ' is archived'))
      this.selectedCheckboxes.forEach(item=> {
        let id = item
        this.paymentsList = this.paymentsList.filter(a=> a.id !== id)
      })
      this.selectedCheckboxes = [];
      // this.$emit("renderList")
    },
    selectCheckboxAll() {
      if(this.selectedCheckboxes.length !== 0) {
        this.selectedCheckboxes = [];
      } else {
        this.selectedCheckboxes = [];
        this.selectedCheckboxes = this.paymentsList.map(a=> {return  a.id})
      }
    },
    selectCheckbox(id) {
      let text = id;
      if(this.selectedCheckboxes.indexOf(text) >=0) {
        this.selectedCheckboxes = this.selectedCheckboxes.filter(a=> a !== text);
      } else {
        this.selectedCheckboxes.push(text);
      }
    },
    // setPositionTotalCalc() {
    //   // this.styleObjectCalc.left = (Number(e.offsetLeft)+1) + 'px';
    //   // this.styleObjectCalc.top = (Number((''+e.offsetParent.style.transform).replace(/[^0-9]/g, "")) + 45) + 'px';
    // },
    mousedown(e) {
      e.element = document.getElementById(e.element)
      let element = e.element.querySelector('.expense');
      e['leftExpense'] = (Number(element.offsetLeft)+1) + Number(element.clientWidth) + 'px';
      element = e.element.querySelector('.income');
      e['leftIncome'] = (Number(element.offsetLeft)+1) + Number(element.clientWidth) + 'px';
      element = e.element.querySelector('.currency.input-payment');
      e['leftCurrency'] = (Number(element.offsetLeft)+1) + Number(element.clientWidth) + 'px';
      this.mouseDown = e;
    },
    mouseup(e) {
      // console.log(e, this.mouseDown && this.mouseDown.index !== e.index)
      if(this.mouseDown && this.mouseDown.index !== e.index && (((this.mouseDown.type === 'expense' || this.mouseDown.type === 'income') && (e.type === 'expense' || e.type === 'income')) || (e.type === this.mouseDown.type))) {
        let startSelection = this.mouseDown.index < e.index ? this.mouseDown : e;
        let endSelection =  this.mouseDown.index > e.index ? this.mouseDown : e;
        let elementsForSelection = [];
        for(let i=startSelection.index; i<=endSelection.index; i++) {
          let types = this.mouseDown.type === e.type ? [e.type] : [this.mouseDown.type, e.type];
          types.forEach(type=> {
            elementsForSelection.push({index: i, id: this.paymentsList[i].id, type: type})
          })
        }
        // console.log(elementsForSelection)
        let list = document.querySelectorAll('.click-active-border');
        this.elementActiveBorder = null;
        list.forEach(item=> {
          item.classList.toggle("click-active-border");
          item.classList.remove("dbl-click-active-input");
          item.setAttribute("disabled", "disabled");
        })
        this.setSelectionPayments([])
        this.setSelectionPayments(elementsForSelection)
      } else {
        if(this.keyCtrlDown) {
          let list = document.querySelectorAll('.click-active-border');
          this.elementActiveBorder = null;
          list.forEach(item=> {
            item.classList.remove("click-active-border");
            item.classList.remove("dbl-click-active-input");
            item.setAttribute("disabled", "disabled");
          })
          if(((e.type === 'expense' || e.type === 'income') && this.typesSelection.filter(a=> a.type === 'currency').length) || (e.type === 'currency' && this.typesSelection.filter(a=> a.type !== 'currency').length)) {
            this.setSelectionPayments([])
          }
          let array = JSON.parse(JSON.stringify(this.typesSelection))
          // console.log(array.find(a=> a.id === e.id && a.type === e.type), e)
          if(array.find(a=> a.id === e.id && a.type === e.type)) {
            this.setSelectionPayments(array.filter(a=> !(a.id === e.id && a.type === e.type)))
          } else {
            array.push(e)
            this.setSelectionPayments(array)
          }
        } else {
          this.setSelectionPayments([])
        }
        // this.setTypesSelection([])
        this.mouseDown = null
      }
    },
    onChange(id) {
      console.log('onChange', this.pausedChange)
      // clearTimeout(this.timeout)
      // this.timeout = setTimeout(()=> {
      if(!this.pausedChange) {
        this.showLoaderIds.push(id)
        let item = this.paymentsList.find(a=> a.id === id)
        if(item) {
          let form = {
            "payment_type": item.payment_type,
            "client_name": item.client_name,
            "currency": item.currency,
            "currency_amount": item.currency_amount ? parseFloat(item.currency_amount) : 0,
            "payment_amount": item.payment_amount ? parseFloat(item.payment_amount) : 0,
            "execute_date": (item.execute_date !== null && (''+item.execute_date).length !== 0) ? moment(item.execute_date, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm') : null,
            "account_name": item.account_name,
            "statement_member": item.statement_member,
            "cp_date": item.cp_date,
            "comment": item.comment,
            "is_archive": item.is_archive,
            "additionally": item.additionally,
            "client": item.client ? item.client.id : null,
            "account": item.account ? item.account.id : null,
            "ports": item.ports ? item.ports.id : null,
            "bank": item.bank ? item.bank.id : null,
            "cash": item.cash ? item.cash.id : null,
            "real_account": item.real_account ? item.real_account.id : null,
            "ship": item.ship ? item.ship.id : null,
            "settled_ship": item.settled_ship ? item.settled_ship.id : null,
          }
          // console.log(form)
          this.checkBalance()
          this.axiosChangeNote({id: id, data: form}).then(res=> {
            console.log(res)
            this.showLoaderIds = this.showLoaderIds.filter(a=> a !== id)
            this.axiosGetGrossNet(this.getFilterDataPaymentAxios);
            if(this.$route.path.indexOf('cash-flow')) {
              this.axiosGetTotalCashFlow(this.getFilterDataPaymentAxios);
            }
          })
        }
      }
      // }, 400)
    },
    getIdPayment(id) {
      return this.paymentsList.findIndex(a=> a.id === id)
    },
    clearActiveBorder() {
      this.elementActiveBorder = null;
      let list = document.querySelectorAll('.click-active-border');
      list.forEach(item=> {
        item.classList.toggle("click-active-border");
      })
      this.setSelectionPayments([])
    },
    setDataPayment(data) {
      this.setSelectionPayments([])

      this.permissionsPayments = [];
      if(this.$route.path.indexOf('cashflow/diagram') >= 0) {
        data = [{
          "id": -1,
          "payment_type": '',
          "client_name": '',
          "currency": '',
          "currency_amount": 0,
          "payment_amount": 0,
          "execute_date": '',
          "account_name": '',
          "statement_member": '',
          "cp_date": '',
          "comment": '',
          "is_archive": false,
          "additionally": '',
          "client": null,
          "account": null,
          "ports": null,
          "bank": null,
          "cash": null,
          "real_account": null,
          "ship": null,
          "settled_ship": null,
          "balance": 0,
          "files": [],
          "receipt_files": [],
        }
          ].concat(data)
      }
      data.forEach((el, index)=> {
        let item = JSON.parse(JSON.stringify(el))
        let obj = JSON.parse(JSON.stringify(this.emptyPermissionDisabledObject))
        obj.due_date = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status) || this.getPermissionBlockBreakDown()
        obj.vessel_state_and_member = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status) || this.getPermissionBlockBreakDown()
        obj.account = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status)
        obj.additionally = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status)
        obj.currency = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status) || this.getPermissionBlockBreakDown()
        // getBoolDontSelect({bank: paymentsList[i].bank, cash: paymentsList[i].cash})
        obj.expense = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status) || this.getPermissionBlockBreakDown()
        obj.income = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status) || this.getPermissionBlockBreakDown()
        obj.converted = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status)
        obj.counterparty = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status) || this.getPermissionBlockBreakDown()
        obj.bank_and_cash_account = (this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status)) || this.getPermissionBlockChangeBank(item.status)
        obj.remark = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status)
        obj.settled_vsl = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status)
        obj.disbursement = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status)
        obj.real_account = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status)
        obj.statement_member = this.getPermissionEditPaymentInStatusPaid(item.status) || this.getPermissionsBlockChangeListInStatusPaid(item.status)
        if(this.$route.path.indexOf('cashflow/diagram')>=0) {
          let balance = index !== 0 ? data[index - 1].balance : this.getAmountBalance;
          if(index !== 0) {
            console.log(el.payment_type === 'expense' ? balance - parseFloat(el.currency_amount) : balance + parseFloat(el.currency_amount), parseFloat(el.currency_amount))
            el['balance'] = el.payment_type === 'expense' ? balance - parseFloat(el.currency_amount) : balance + parseFloat(el.currency_amount)
          } else {
            el['balance'] = balance
          }
        }
        this.disabledPayments.push(obj)
      })
      this.paymentsList =(JSON.parse(JSON.stringify(data)))
      setTimeout(()=> {
        if(this.$route.path.indexOf('cash-flow')) {
          this.axiosGetTotalCashFlow(this.getFilterDataPaymentAxios);
        }
        this.axiosGetGrossNet(this.getFilterDataPaymentAxios)
      }, 280)
      setTimeout(()=> {
        this.keyRender++;
      }, 240)
      this.checkAutoScroll();

    },
    checkBalance() {
      if(this.$route.path.indexOf('cashflow/diagram')>=0) {
        this.paymentsList.forEach((el, index)=> {
          let balance = index !== 0 ? this.paymentsList[index - 1].balance : this.getAmountBalance;
          if(index !== 0) {
            console.log(el.payment_type === 'expense' ? balance - parseFloat(el.currency_amount) : balance + parseFloat(el.currency_amount), parseFloat(el.currency_amount))
            el['balance'] = el.payment_type === 'expense' ? balance - parseFloat(el.currency_amount) : balance + parseFloat(el.currency_amount)
          } else {
            el['balance'] = balance
          }
        })
      }
    },
    setActiveColumns(data) {
      this.activeColumnsDefault = data;
      localStorage.removeItem('activeColumns' + this.$route.path);
      localStorage.setItem('activeColumns' + this.$route.path, JSON.stringify(this.activeColumnsDefault));
      this.nameColumns.forEach(a=> {
        let el = this.elementsGridSettings.elements.find(b=> b.name === a.value)
        let bool = this.activeColumnsDefault.find(b=> b.id === a.id)
        if(bool) {
          el.show = true
        } else {
          el.show = false
        }
      })
    },
    getPermissionShowVesselAndCpDate() {
      return this.getPermissionsByType('show_vessel_and_cp_date_in_payments')
    },
    getPermissionShowDopColumns() {
      return !this.getPermissionsByType('show_dop_menu_choosed_columns_in_payment_list')
    },
    getPermissionShowDopColumnsFull() {
      return this.getPermissionsFullByType('show_dop_menu_choosed_columns_in_payment_list')
    },
    getPermissionEditPaymentInStatusPaid(status) {
      return (!this.getPermissionsByType('can_edit_payment_in_status_paid') && status === 'paid')
    },
    getPermissionsBlockChangeListInStatusPaid(status) {
      return (this.getPermissionsByType('block_the_payment_income_row_in_the_status_paid_in_the_status_paid_in_the_list_of_payments_incomes') && status === 'paid')
    },
    getPermissionBlockBreakDown() {
      return this.getPermissionsByType('block_for_editing_everything_in_the_tab_cash_flow_diagram') && this.$route.path.indexOf('cashflow/diagram') >= 0;
    },
    getPermissionBlockChangeBank(status) {
      return this.getPermissionsByType('hide_the_block_bank_and_cash_accounts_in_the_status_not_paid') && status === 'not_paid'
    },
    async checkAutoScroll() {
      await this.$nextTick()
      let vm = this;
      let bool = this.$route.path.indexOf('cashflow/list') >= 0 || this.$route.path.indexOf('vessel')>=0 || this.$route.path.indexOf('cashflow/diagram') >= 0 || this.$route.path.indexOf('counterparties') >= 0
      let element = (bool && this.$route.path.indexOf('cashflow/diagram') === -1) ? document.querySelector('.wrap-page') : (this.$route.path.indexOf('cashflow/diagram') >= 0 || this.$route.path.indexOf('cashflow/new-diagram') >= 0) ? document.querySelector('.payment-block') : window;
      console.log(bool, element)
      if(bool) {
          let scroll = element.scrollTop;
          let height = element.scrollHeight - element.clientHeight
        // console.log(1, height - scroll, height, scroll, height>0 , height - scroll <= 0, element.scrollHeight, element.clientHeight)
//test -15
        console.log('Math.floor(height)-15 - Math.ceil(scroll)', Math.floor(height)-15 - Math.ceil(scroll))
        if( (height>0 && Math.floor(height)-50 - Math.ceil(scroll) <= 0) || element.scrollHeight === element.clientHeight) {
            // console.log(1, height - scroll, height, scroll, height>0 , height - scroll <= 0, element.scrollHeight, element.clientHeight)
            vm.$emit('scrollBottomEnd')
          }
      } else {
          if ((element.innerHeight + element.pageYOffset + 40) >= document.body.offsetHeight) {
            vm.$emit('scrollBottomEnd')
            // console.log(2)
            if(vm.$route.path.indexOf('paymnet/list')) {
              vm.paymentsList = JSON.parse(JSON.stringify(vm.paymentsList))
            }
          }
      }
    },
    mountedOperation() {
      this.setSelectionPayments([])

      let vm= this;
      document.addEventListener('keydown', function(event) {
        if (event.key === 'Control' || event.key === 'Meta') {
          vm.keyCtrlDown = true
        } else {
          vm.keyCtrlDown = false
        }
      });
      let bool = this.$route.path.indexOf('cashflow/list') >= 0 || this.$route.path.indexOf('vessel')>=0 || this.$route.path.indexOf('cashflow/diagram') >= 0 || this.$route.path.indexOf('counterparties') >= 0
      let element = (bool && this.$route.path.indexOf('cashflow/diagram') === -1) ? document.querySelector('.wrap-page') : (this.$route.path.indexOf('cashflow/diagram') >= 0 || this.$route.path.indexOf('cashflow/new-diagram') >= 0) ? document.querySelector('.payment-block') : window;
      console.log(bool, element)
      if(bool) {
        element.onscroll = function() {
          if(vm.elementActiveBorder) {
            console.log(document.querySelector(vm.elementActiveBorder))
            let element = document.querySelector(vm.elementActiveBorder)
            if(element) {
              if(!element.matches('.click-active-border')) {
                element.classList.toggle("click-active-border");
              }
            }
          }
          let scroll = element.scrollTop;
          let height = element.scrollHeight - element.clientHeight;
          // console.log(1,  Math.floor(height) - Math.ceil(scroll), height, scroll)
          if( height>0 && Math.floor(height) - Math.ceil(scroll) <= 0 ) {
            vm.$emit('scrollBottomEnd')
          }
        }
      } else {
        element.onscroll = function() {
          if(vm.elementActiveBorder) {
            console.log(document.querySelector(vm.elementActiveBorder))
            let element = document.querySelector(vm.elementActiveBorder)
            if(element) {
              if(!element.matches('.click-active-border')) {
                element.classList.toggle("click-active-border");
              }
            }
          }
          if ((element.innerHeight + element.pageYOffset) >= document.body.offsetHeight) {
            vm.$emit('scrollBottomEnd')
            if(vm.$route.path.indexOf('paymnet/list')) {
              console.log(2)
              vm.paymentsList = JSON.parse(JSON.stringify(vm.paymentsList))
            }
          }
        };
      }
      // window.onscroll = function() {
      //   if(vm.elementActiveBorder) {
      //     console.log(document.querySelector(vm.elementActiveBorder))
      //     let element = document.querySelector(vm.elementActiveBorder)
      //     if(element) {
      //       if(!element.matches('.click-active-border')) {
      //         element.classList.toggle("click-active-border");
      //       }
      //     }
      //   }
      // // .matches('.click-active-border')
      // }

      document.addEventListener('keyup', function() {
        vm.keyCtrlDown = false
      });
      document.addEventListener('click', function (event) {
        function closest(el, selector) {
          if (Element.prototype.closest) {
            return el.closest(selector);
          }
          let parent = el;
          while (parent) {
            if (parent.matches(selector)) {
              return parent;
            }
            parent = parent.parentElement;
          }
          return null;
        }
        let clickElement = event.target.matches('.pre-input-click-border') ?  event.target.previousSibling : event.target
        console.dir('click', clickElement)

        // console.log(event.target.parentElement.getAttribute('class').replaceAll('v-tooltip-open', '').replaceAll('has-tooltip', '').trim().replaceAll(' ', '.'), closest(event.target, '.payment-item').getAttribute('id'))
        if(!vm.keyCtrlDown) {
          if ((!clickElement.matches('.click-element-border') || clickElement.matches('.click-active-border'))) return;
          console.log(clickElement)
          event.preventDefault();
          let list = document.querySelectorAll('.click-active-border');
          list.forEach(item=> {
            item.classList.toggle("click-active-border");
            item.classList.remove("dbl-click-active-input");
            item.setAttribute("disabled", "disabled");
          })
          vm.setSelectionPayments([])
          clickElement.classList.toggle("click-active-border");
          vm.elementActiveBorder = '#'+closest(clickElement, '.payment-item').getAttribute('id') + ' .' + clickElement.parentElement.getAttribute('class').replaceAll('v-tooltip-open', '').replaceAll('has-tooltip', '').trim().replaceAll(' ', '.') + ' .click-element-border'

        }
      }, false);
      document.addEventListener('dblclick', function (event) {
        if(!vm.keyCtrlDown) {
          let clickElement = event.target.matches('.pre-input-click-border') ?  event.target.previousSibling : event.target
          console.log('dbclick', clickElement)
          if ((!clickElement.matches('.click-element-border') || clickElement.matches('.disabled-always') || clickElement.matches('.dbl-click-active-input') || clickElement.localName !== 'input')) return;
          event.preventDefault();
          clickElement.classList.toggle("dbl-click-active-input");
          clickElement.removeAttribute('disabled')
          setTimeout(()=> {
            clickElement.focus()
            if(!clickElement.matches('.input-payment'))
              clickElement.selectionStart = clickElement.value.length;
            clickElement.setSelectionRange(0, clickElement.value.length)
            // clickElement.selectionStart = clickElement.value.length;

            // clickElement.current.select()
          }, 50)
        }

      }, false);


      if(this.namePages.indexOf(this.$route.name) === -1) {
        this.namePages = [];
        this.namePages.push(this.$route.name);
      }

      this.nameColumns = [
        {name: "Vessel, CP Date", id: '3228734654s'+0, value: 'vessel-state-and-member'},
        {name: "Statement Member", id: '312224s'+0, value: 'statement-member'},
        {name: "Settled vsl", id: '324s'+0, value: 'settled-vsl'},
        {name: "USD amount", id: '324s'+1, value: 'converted'},
        {name: "Disbursements", id: '324s'+2, value: 'disbursement'},
        {name: "Real Account", id: '324s'+3, value: 'real-account'},
      ]
      if(this.$route.path.indexOf('vessels') >= 0) {
        this.nameColumns.push({name: "Bank and Cash Accounts", id: '324s'+3},)
      }
      if(this.$route.path.indexOf('open/cash-flow') >= 0) {
        this.nameColumns = [
          {name: "Vessel, CP Date", id: '3228734654s'+0, value: 'vessel-state-and-member'},
          {name: "Statement Member", id: '32323224s'+0, value: 'statement-member'},
          {name: "Bank and Cash Accounts", id: '3224s'+3, value: 'bank-and-cash-account'},
          {name: "Settled vsl", id: '324s'+0, value: 'settled-vsl'},
          {name: "USD amount", id: '324s'+1, value: 'converted'},
          {name: "Disbursements", id: '324s'+2, value: 'disbursement'},
          {name: "Real Account", id: '324s'+3, value: 'real-account'},
        ]
      }

      if(this.$route.path.indexOf('counterparties') >=0 && this.$route.path.indexOf('payments') >=0) {
        this.nameColumns = [
          {name: 'Attachments', id: '867sd'+144, value: 'attachment'},
          {name: 'Comment from accounting', id: '867sd'+2, value: 'comment-from-accounting'},
          {name: 'Bank Receip', id: '867sd'+1, value: 'bank-receipt'},
          {name: "Bank and Cash Accounts", id: '3224s'+3, value: 'bank-and-cash-account'},
          {name: "Vessel, CP Date", id: '3228734654s'+0, value: 'vessel-state-and-member'},
          {name: "Statement Member", id: '322123124s'+0, value: 'statement-member'},
          {name: "Settled vsl", id: '324s'+0, value: 'settled-vsl'},
          {name: "USD amount", id: '324s'+1, value: 'converted'},
          {name: "Disbursements", id: '324s'+2, value: 'disbursement'},
          {name: "Real Account", id: '324s'+3, value: 'real-account'},
        ]
      }
      if(this.$route.path.indexOf('cashflow/list') >= 0) {
        this.nameColumns = [
          {name: "Bank and Cash Accounts", id: '3224s'+3, value: 'bank-and-cash-account'},
          {name: "Vessel, CP Date", id: '3228734654s'+0, value: 'vessel-state-and-member'},
          {name: "Statement Member", id: '322234ff4s'+0, value: 'statement-member'},
          {name: "Settled vsl", id: '324s'+0, value: 'settled-vsl'},
          {name: "USD amount", id: '324s'+1, value: 'converted'},
          {name: "Disbursements", id: '324s'+2, value: 'disbursement'},
          {name: "Real Account", id: '324s'+3, value: 'real-account'},
        ]
      }
      if(this.$route.path.indexOf('open/accounting-invoice') >= 0) {
        this.nameColumns = [
          {name: "Vessel, CP Date", id: '3228sd734654s'+0, value: 'vessel-state-and-member'},
          {name: "Statement Member", id: '321232sd4s'+0, value: 'statement-member'},
          {name: "Settled vsl", id: '32tt4s'+11, value: 'settled-vsl'},
          {name: "USD amount", id: '32zz4s'+123, value: 'converted'},
          {name: "Disbursements", id: '32sss4s'+2, value: 'disbursement'},
          // {name: "Real Account", id: '324s'+3},
        ]
        let elementHide = ['status', 'bank-receipt', 'comment-from-accounting', 'real-account', 'paid-date', 'bank-and-cash-account']
        elementHide.forEach(name=> {
          let item = this.elementsGridSettings.elements.find(a=> a.name === name)
          item.show = false
        })
      }
      if(this.$route.path.indexOf('counterparties')>=0) {
        let elementHide = ['disbursement', 'real-account']
        elementHide.forEach(name=> {
          let item = this.elementsGridSettings.elements.find(a=> a.name === name)
          item.show = false
        })

      }
      if(!this.getPermissionShowVesselAndCpDate()) {
        this.nameColumns = this.nameColumns.filter(a=> a.name !== "Vessel, CP Date")
        let el = this.elementsGridSettings.elements.find(a=> a.name === 'vessel-state-and-member')
        el.show = false
        // this.elementsGridSettings.elements.forEach(item=> {
        //   if(item.name === 'vessel-state-and-member') {
        //     item.show = false
        //   }
        // })
      }
      // console.log('this.getPermissionShowDopColumnsFull().such_columns', this.getPermissionShowDopColumnsFull().such_columns)
      if(!this.getPermissionShowDopColumns()) {
        this.nameColumns = this.nameColumns.filter(a=> (this.getPermissionShowDopColumnsFull().such_columns.map(a=> {return a.toLowerCase()})).indexOf(a.name.toLowerCase()) === -1)
        this.getPermissionShowDopColumnsFull().such_columns.forEach(a=> {
          if(a === 'Settled Vsl') {
            let el = this.elementsGridSettings.elements.find(a=> a.name === 'settled-vsl')
            el.show = false
          }
          if(a === 'Real Account') {
            let el = this.elementsGridSettings.elements.find(a=> a.name === 'real-account')
            el.show = false
          }
          if(a === 'Statement member') {
            let el = this.elementsGridSettings.elements.find(a=> a.name === 'statement-member')
            el.show = false
          }
          if(a === 'Bank and cash accounts') {
            let el = this.elementsGridSettings.elements.find(a=> a.name === 'bank-and-cash-account')
            el.show = false
          }
        })
      }
      if(this.$route.path.indexOf('accounting-invoice')>=0) {
        this.nameColumns = this.nameColumns.filter(a=> a.name !== "Statement Member")
        let el = this.elementsGridSettings.elements.find(a=> a.name === 'statement-member')
        el.show = false
      }
      if(this.$route.path.indexOf('counterparties') >= 0) {
        this.nameColumns = this.nameColumns.filter(a=> a.name !== "Disbursements" && a.name !== "Real Account")
      }

      if(localStorage.getItem("activeColumns" + this.$route.path) !== null && localStorage.getItem("activeColumns" + this.$route.path) !== undefined) {
        let IDS = this.nameColumns.map(a=> {return a.id})
        this.activeColumnsDefault = JSON.parse(localStorage.getItem("activeColumns" + this.$route.path));
        // console.log('this.activeColumnsDefault', this.activeColumnsDefault)
        this.activeColumnsDefault = this.activeColumnsDefault.filter(a=> IDS.indexOf(a.id) >=0 )
        // this.activeColumns = JSON.parse(localStorage.getItem("activeColumns" + this.$route.path));
      }
      this.nameColumns.forEach(a=> {
        let el = this.elementsGridSettings.elements.find(b=> b.name === a.value)
        let bool = this.activeColumnsDefault.find(b=> b.id === a.id)
        if(bool) {
          el.show = true
        } else {
          el.show = false
        }
      })
      if(this.$route.path.indexOf('cashflow/diagram')>=0) {
        this.nameColumns = this.nameColumns.filter(a=> a.name !== "Vessel, CP Date")
        this.hideDropMenu = true
        this.elementsGridSettings.orderElements = ['block-for-checkbox', 'vessel-state-and-member', 'additionally', 'currency', 'expense', 'income', 'converted',
          'status', 'paid-date', 'counterparty', 'account', 'due-date', 'bank-and-cash-account', 'remark', 'attachment', 'bank-receipt', 'comment-from-accounting', 'settled-vsl', 'disbursement', 'real-account', 'statement-member', 'balance', 'end-block']
        let arrayHide = ['additionally', 'converted', 'status', 'paid-date', 'bank-and-cash-account', 'remark', 'attachment', 'bank-receipt', 'comment-from-accounting', 'settled-vsl', 'disbursement', 'real-account', 'statement-member'];
        this.elementsGridSettings.elements.forEach(item=> {
          if(arrayHide.indexOf(item.name) >= 0) {
            item.show = false
          }
          if(item.name === 'balance' || item.name === 'vessel-state-and-member') {
            item.show = true;
          }
        })
      } else if(this.$route.path.indexOf('cashflow/new-diagram')>=0) {
        this.nameColumns = this.nameColumns.filter(a=> a.name !== "Vessel, CP Date")
        this.hideDropMenu = true
        this.elementsGridSettings.orderElements = ['block-for-checkbox', 'vessel-state-and-member', 'additionally', 'currency', 'expense', 'income', 'converted',
          'status', 'paid-date', 'counterparty', 'account', 'due-date', 'bank-and-cash-account', 'remark', 'attachment', 'bank-receipt', 'comment-from-accounting', 'settled-vsl', 'disbursement', 'real-account', 'statement-member', 'end-block']
        let arrayHide = ['additionally', 'converted', 'status', 'paid-date', 'bank-and-cash-account', 'remark', 'attachment', 'bank-receipt', 'comment-from-accounting', 'settled-vsl', 'disbursement', 'real-account', 'statement-member'];
        this.elementsGridSettings.elements.forEach(item=> {
          if(arrayHide.indexOf(item.name) >= 0) {
            item.show = false
          }
          if(item.name === 'balance' || item.name === 'vessel-state-and-member') {
            item.show = true;
          }
        })
      }
      if(this.shortView) {
        this.nameColumns.forEach(a=> {
          let el = this.elementsGridSettings.elements.find(b=> b.name === a.value)
          if(this.shortView) {
            el.show = false
          }
        })
      } else {
        this.setActiveColumns(this.activeColumnsDefault)
      }
    }
  },
  mounted() {
    this.mountedOperation();
    window.addEventListener('resize', () => {
      this.checkAutoScroll()
    });
  }
}
</script>

<style scoped lang="scss">
  .scroller {

  }
  .block-payments {
    border: 1px solid var(--color-blue-dark);
    box-sizing: border-box;
    border-radius: 8px;
  }
  /deep/ {
    .vue-recycle-scroller__item-view {
      box-sizing: border-box;
      &:first-child {
        border-top: 1px solid var(--color-blue-dark);
      }
      &:not(:first-child) {
        border-top: 1px solid var(--color-border);
      }
      //&:nth-child(odd) {
      //  background-color: var(--color-bgc-page-white);;
      //}
      //&:nth-child(even) {
      //  background-color: var(--color-white-blue);;
      //}
      &:last-child {
        .payment-item {
          &>*:first-child {
            border-radius: 0 0 0 8px;
          }
          &>*:last-child {
            border-radius: 0 0 8px 0;
          }
        }
        //border-radius: 0 0 8px 8px;
      }
    }
  }
  .titles {
    /deep/ {
      &>*:not(:first-child) {
        border-left: 1px solid var(--color-border);
      }
    }
  }
  .payment-item {

    //grid-template-areas:
    //" block1 blovk2 ssad asdasd";
    //display: flex;
    //width: 100%;
    //height: 30px;
    //display: grid;
    /deep/ {
      &>*:not(:first-child) {
        border-left: 1px solid var(--color-border);
      }
      input {
        transition: 0s;
        background-color: transparent;
      }
      &>* {
        overflow: hidden;
      }
    }

  }
  /deep/ {
    .text-info {
      box-sizing: border-box;
      height: 30px;
      border: 2px solid transparent;
      padding: 4px;
      padding-top: 6px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: flex;
      align-items: center;
    }
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid transparent;
      height: 30px;
      width: 100%;
      box-sizing: border-box;
    }
    .needed-after {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    .block-for-checkbox {
      grid-area: block-for-checkbox;
      padding: 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      //width: 35px;
      box-sizing: border-box;
    }
    .due-date {
      grid-area: due-date;
      display: flex;
      align-items: center;
      //min-width: 80px;
      //width: 80px;
      //width: 5.9%;
      input {
        padding: 4px;
        padding-right: 2px;
        height: 100%;
        border: none;
        border-radius: 0px;
      }
    }
    .vessel-state-and-member {
      grid-area: vessel-state-and-member;
      //width: 9%;
      min-width: 62px;
      height: 30px;
      input {
        height: 100%;
      }
    }
    .account {
      grid-area: account;
      height: 30px;
      //width: 13.8%;
      //@media (max-width: 1650px) {
      //  width: 10.8%;
      //  min-width: 64px;
      //}
    }
    .additionally {
      grid-area: additionally;
      //width: 11.8%;
      //@media(max-width: 1650px) {
      //  min-width: 64px;
      //  width: 10.8%;
      //}
    }
    .currency {
      grid-area: currency;
      //width: 40px;
    }
    .expense {
      grid-area: expense;
      min-width: 110px;

      ////min-width: 130px;
      ////width: 13%;
      //@media(max-width: 1650px) {
      //  min-width: 100px;
      //  width: 11%;
      //}
    }
    .income {
      grid-area: income;
      min-width: 110px;

    }
    .converted {
      grid-area: converted;
      min-width: 64px;
      //width: 11%;
      //@media (max-width: 1650px) {
      //  min-width: 64px;
      //  width: 4.8%;
      //}
    }
    .status {
      grid-area: status;
      //min-width: 67px;
      //width: 67px;
      p {
        line-height: 1.084rem !important;
      }
    }
    .paid-date {
      grid-area: paid-date;
      //min-width: 78px;
      //width: 7%;
    }
    .counterparty {
      min-width: 63px;
      grid-area: counterparty;
      //width: 10.6%;
      //@media (max-width: 1650px) {
      //  width: 11%;
      //  min-width: 64px;
      //}

    }
    .bank-and-cash-account {
      grid-area: bank-and-cash-account;
    }
    .remark {
      grid-area: remark;
      //width: 10.3%;
      //@media (max-width: 1650px) {
      //  width: 11%;
      //  min-width: 64px;
      //}
    }
    .attachment {
      grid-area: attachment;
    }
    .comment-from-accounting {
      grid-area: comment-from-accounting;
    }
    .settled-vsl {
      min-width: 57px;
      grid-area: settled-vsl;
    }
    .disbursement {
      min-width: 57px;
      grid-area: disbursement;
    }
    .real-account {
      min-width: 57px;
      grid-area: real-account;
    }
    .statement-member {
      grid-area: statement-member;
    }
    .balance {
      grid-area: balance;
    }
    .end-block {
      grid-area: end-block;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .click-active-border {
      border: 2px solid var(--color-yellow) !important;
      background-color: var(--color-gray-dark) !important;
    }
    .border-select-top {
      border-top: 2px solid var(--color-yellow) !important;
    }
    .border-select-bottom {
      border-bottom: 2px solid var(--color-yellow) !important;
    }
    .border-select-right {
      border-right: 2px solid var(--color-yellow) !important;
    }
    .border-select-left {
      border-left: 2px solid var(--color-yellow) !important;
    }
    .border-select-top, .border-select-bottom, .border-select-right, .border-select-left {
      background-color: var(--color-gray-dark) !important;
    }
  }
  .drop-colums {
    position: absolute;
    top: 1px;
    right: 0;
    height: 44px;
    width: 35px;
    margin: 0 !important;
    /deep/ {
      .btn {
        opacity: 0;
        height: 44px;
        width: 35px;
      }
      .dropdown-menu {
        transform: none !important;
        width: 200px !important;
        top: 44px !important;
        left: auto !important;
        right: 0 !important;
      }
    }
  }
  .total-calc {
    position: absolute;
    width: max-content;
    background-color: var(--color-dark);
    z-index: 50;
    padding: 5px 8px;
    box-sizing: border-box;
  }
  .no-payments {
    margin-top: 36px;
    width: 100%;
    box-sizing: border-box;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
      margin-top: 16px;
    }
    &__button {
      margin-top: 8px;
    }
  }
  .border-none {
    border: none !important;
  }
  .bottom-panel {
    display: flex;
    align-items: center;
    padding: 6px 36px;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    left: 0;
    background-color: var(--color-card);
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 44px;
    .text {
      span {
        color: var(--color-blue-dark);
      }
    }
    .cancel {
      margin-left: 20px;
      cursor: pointer !important;
      svg {
        cursor: pointer !important;

        path {
          fill: #9AA3B0;
          transition: .1s linear;
        }
      }
      &:hover {
        svg path {
          fill: var(--color-dark);
        }
      }
    }
    .unarchive {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 132px;
      height: 32px;
      background-color: var(--color-blue-dark);
      color: #FFFFFF;
      border-radius: 4px;
      svg {
        margin-right: 5px;
        path {
          fill: #FFFFFF;
        }
      }

    }
  }

  /deep/ {
    .vue-recycle-scroller__item-wrapper {
      &>*:nth-child(odd) {
        .loader {
          background-color: var(--color-bgc-page-white);
        }
      }
      &>*:nth-child(even) {
        .loader {
          background-color: var(--color-white-blue);
        }
      }
      &>*:last-child {
        .payment-item {
          border-radius: 0 0 10px 10px;
        }
      }
      &:last-child {
        border-radius: 0 0 9px 9px
      }
    }

  }
.asdas {
  position: absolute;
  left: 0;
}
.pdf-wrap {
  padding: 5px !important;
  box-sizing: border-box;
  //border: 1px solid var(--color-blue-dark);
  //border-radius: 8px;
  width: 1585px;
}
.item-pdf {
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-blue-dark);
  }
  .due-date {
    display: block;
  }
  &>* {
    height: 100% !important;
    min-height: 30px;
    display: flex;
    align-items: flex-start;
    &>* {
      vertical-align: text-top;
      border: none !important;
      padding: 6px;
      word-break: break-all;
    }
  }

  border-bottom: 1px solid var(--color-border);
  //height: max-content !important;
}
.block-wrap-pdf-content {
  border: 1px solid var(--color-blue-dark);
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
}
.border-left {
  border-left: 1px solid var(--color-border)
}
</style>